<template>
  <UPopover
    :ui="{ popper: { placement: 'top' }, rounded: 'rounded-2xl' }"
    class="flex items-center"
  >
    <ArrowULeftTopIcon
      v-tooltip.top="t('general.recover')"
      :size="16"
      class="block cursor-pointer text-signal-blue-500 transition-colors hover:text-signal-blue-600"
      @click="emit('clicked')"
    />

    <template #panel="{ close }">
      <h3 class="mb-3 text-lg font-semibold text-aubergine-900">
        {{ $t('general.recover') }}
      </h3>
      <p class="mb-4">
        {{ $t('parcel.parcelItemRecoverText') }}
      </p>
      <div class="mt-6 grid grid-cols-2 gap-4">
        <WpButton variant="dark-outline" @click="close">
          {{ $t('general.cancel') }}
        </WpButton>
        <WpButton variant="signal-blue" @click="emit('recoverParcelItem')">
          {{ $t('general.recover') }}
        </WpButton>
      </div>
    </template>
  </UPopover>
</template>

<script setup lang="ts">
const { t } = useI18n()

const emit = defineEmits(['clicked', 'recoverParcelItem'])
</script>
